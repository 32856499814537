/* eslint-disable import/extensions */

// Services Img Imports
import Web from "../assets/images/Services/Web.png";
import Mobile from "../assets/images/Services/Mobile.png";
import UIUX from "../assets/images/Services/Design.png";

// Portfolio Img Imports
import Recruiting from "../assets/images/Portfolio/Recruiting.png";
import Stream from "../assets/images/Portfolio/Stream.png";
import Freelance from "../assets/images/Portfolio/Freelance.png";
import Aura from "../assets/images/Portfolio/Aura.png";
import Surtido from "../assets/images/Portfolio/Surtido.png";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";

// Testimonials
import Sasha from "../assets/images/Testimonials/Sasha.jpg";
import Ramesh from "../assets/images/Testimonials/ramesh.jpg";
import Emily from "../assets/images/Testimonials/emily.jpg";
import Pratik from "../assets/images/Testimonials/pratik.webp";
import Sita from "../assets/images/Testimonials/sita.jpg";
import John from "../assets/images/Testimonials/john.jpg";

// TeamMembers
import CEO from "../assets/images/TeamMembers/milan2.jpg";
import COO from "../assets/images/TeamMembers/dhanan.jpg";
import CTO from "../assets/images/TeamMembers/sachet.jpg";
import ProjectManager from "../assets/images/TeamMembers/shashi.jpg";
import Sidd from "../assets/images/TeamMembers/sidd.jpg";
import Alisha from "../assets/images/TeamMembers/alisha.png";
import Rahul from "../assets/images/TeamMembers/rahul.jpg";
import Haru from "../assets/images/TeamMembers/Haru.jpg";

export const Services = [
  {
    title: "Web Development",
    imageUrl: Web,
    animation: "left",
  },
  {
    title: "Mobile Development",
    imageUrl: Mobile,
    animation: "up",
  },
  {
    title: "UI/UX Design",
    imageUrl: UIUX,
    animation: "right",
  },
];

export const Portfolios = [
  {
    id: "asd1293uasdads1",
    title: "Recruiting App",
    imageUrl: Recruiting,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design"],
    details:
      "The Recruiting App is a comprehensive platform designed to streamline the hiring process for employers and job seekers. Employers can easily post job listings, manage applications, and track candidates throughout the hiring journey. Job seekers can quickly apply for positions, upload resumes, and receive updates on their application status. The app features interview scheduling, secure data management, and a user-friendly interface, ensuring a smooth and efficient recruitment process. With its intuitive design and powerful tools, it helps businesses find the right talent while offering job seekers an accessible platform to explore new opportunities.",
  },
  {
    id: "asd1293uhjkhkjh2",
    title: "Stream+",
    imageUrl: Stream,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design"],
    details:
      "Stream+ is a dynamic mobile app designed to provide users with an exceptional streaming experience. As part of the project, responsibilities included mobile development and UI/UX design to ensure a seamless and engaging interface. The app offers a smooth, intuitive experience for users to access and enjoy content across various platforms. With a focus on performance, design, and user satisfaction, Stream+ stands out as a top-tier solution in the mobile app space.",
  },
  {
    id: "asd1293uvbvcbbd3",
    title: "Freelance",
    imageUrl: Freelance,
    type: "Mobile Apps",
    responsibility: ["Mobile Development", "UI/UX Design"],
    details:
      "Freelance is a platform designed to connect businesses with skilled freelancers for a wide range of services. The app focuses on providing a seamless experience for both employers and freelancers, enabling easy project posting, bidding, and collaboration. Key responsibilities in this project included mobile development and UI/UX design, ensuring a user-friendly interface and smooth navigation. The app allows freelancers to showcase their skills, set their rates, and manage their projects efficiently, while employers can quickly find and hire the best talent for their needs.",
  },
  {
    id: "asd1293ufgdfgs4",
    title: "Aura",
    imageUrl: Aura,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
    details:
      "Aura is a music website designed to help users create personalized playlists based on their preferences and moods. The platform allows users to discover, curate, and enjoy their favorite tracks effortlessly. Responsibilities for this project included web development and UI/UX design, ensuring the site is not only functional but also visually engaging and easy to navigate. With a focus on user experience, Aura offers a seamless interface for music lovers to create and share custom playlists, making every listening experience unique and tailored to individual tastes.",
  },
  {
    id: "asd1293ulskmnb5",
    title: "Surtido Rico",
    imageUrl: Surtido,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
    details:
      "Surtido Rico is a clothing eCommerce website designed to offer a seamless online shopping experience for fashion enthusiasts. The platform features a wide range of trendy and stylish apparel, making it easy for users to browse, select, and purchase clothing items. Responsibilities for this project included web development and UI/UX design, ensuring an intuitive, visually appealing, and user-friendly interface. Surtido Rico focuses on delivering a smooth shopping journey, with easy navigation, secure payment options, and an engaging design to enhance the overall customer experience.",
  },
  {
    id: "asd1293ulkmnbj6",
    title: "Courses Management",
    imageUrl: ManagementApp,
    type: "Website",
    responsibility: ["Web Development", "UI/UX Design"],
    details:
      "Courses Management is a website designed to streamline the management and administration of online courses. The platform allows instructors to create, organize, and manage course materials, while students can easily access and track their progress. Responsibilities for this project included web development and UI/UX design, ensuring a user-friendly interface for both educators and learners. With features like course enrollment, progress tracking, and content management, Courses Management enhances the learning experience by making course delivery more efficient and accessible.",
  },
];

export const Advantages = [
  [
    {
      title: "Communicative",
      description:
        "We communicate our project ideas and progress to make it clear.",
      imageUrl: Communicative,
    },
    {
      title: "Management",
      description:
        "We manage our project properly to make our project done well.",
      imageUrl: Management,
    },
  ],
  [
    {
      title: "Collaborative​",
      description:
        "Our team are very collaborative to make our project done well.",
      imageUrl: Collaborative,
    },
    {
      title: "Favorite",
      description: "We've did so many project and all of our client love it.",
      imageUrl: Favorite,
    },
  ],
];
export const Testimonials = [
  {
    id: 1,
    name: "Ramesh Adhikari",
    company: "Director, Everest Softworks",
    testimoni:
      "Tridev Innovation has elevated our operations to a new level. Truly impressed with their service!",
    imageUrl: Ramesh,
  },
  {
    id: 2,
    name: "Emily Carter",
    company: "Founder, Greenwave Solutions",
    testimoni:
      "Working with Tridev was seamless and productive. Their creativity and support stand out!",
    imageUrl: Emily,
  },
  {
    id: 3,
    name: "Pratik Shrestha",
    company: "CTO, Digiflux Labs",
    testimoni:
      "Reliable, innovative, and results-driven—Tridev Innovation is the partner every business needs!",
    imageUrl: Pratik,
  },
  {
    id: 4,
    name: "Sasha Tanaka",
    company: "CEO, Horizon Dynamics",
    testimoni:
      "The solutions provided by Tridev Innovation exceeded all expectations. Highly recommended!",
    imageUrl: Sasha,
  },
  {
    id: 5,
    name: "Sita Khadka",
    company: "Manager, Skyframe Media",
    testimoni:
      "Tridev’s professionalism and commitment to excellence are truly inspiring. Thank you for your hard work!",
    imageUrl: Sita,
  },
  {
    id: 6,
    name: "John Williams",
    company: "COO, Brightwave Systems",
    testimoni:
      "Collaborating with Tridev was one of our best decisions. Exceptional team and flawless execution!",
    imageUrl: John,
  },
];

export const TeamMembers = [
  {
    name: "Milan Pokharel",
    position: "CEO",
    imageUrl: CEO,
  },
  {
    name: "Dhananjaya Raut",
    position: "COO",
    imageUrl: COO,
  },
  {
    name: "Sachet Khatiwada",
    position: "CTO",
    imageUrl: CTO,
  },
  {
    name: "Shashi Shah",
    position: "Project Manager",
    imageUrl: ProjectManager,
  },
  {
    name: "Siddharta Yadav",
    position: "Backend Developer",
    imageUrl: Sidd,
  },
  {
    name: "Alisha Khadka",
    position: "Senior Firmware Developer",
    imageUrl: Alisha,
  },
  {
    name: "Rahul Tharu",
    position: "Frontend Developer",
    imageUrl: Rahul,
  },
  {
    name: "Haru Hirachan",
    position: "IoT Engineer",
    imageUrl: Haru,
  },
];
