/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import Button from '../elements/Button';
import logo from '../assets/images/Portfolio/logo.png';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <p className="text-theme-blue text-4xl font-medium ">
        
        <img className="h-16" src={logo} alt="" />
      </p>
      
    </Button>
  );
}
